<template>
  <div>
    <list-layout ref="govMultiLevel" :filter-form="filterForm" :thead="thead"
                 :on-fetch="getList" :on-edit="onEdit"
    >
      <template #filter>
        <multi-level-list-layout :filterForm="filterForm" :uploadFilter="ok" />
      </template>
    </list-layout>
  </div>
</template>

<script>
import ListLayout from "@/base/layout/ListLayout";
import { getGovMultiLevels } from "@/modules/gov/api/multi-level-library";
import MultiLevelListLayout from "@/modules/multi-level-admin/components/ListFilter";

export default {
  components: {MultiLevelListLayout, ListLayout},
  data() {
    return {
      filterForm: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        keyword: "", //组织名称
        // pid: -2, //所属组织
        tab: "normal", //当前筛选tab
        page_size: 15, //每页多少条数据
        project_id: this.$store.getters.tenant.id || ''
      },
      thead: [
        { label: "ID", prop: "id", width: 100 },
        {label: "所属商协会", prop: "project_name", minWidth: 100},
        { label: "组织名称", prop: "name", minWidth: 150 },
        {
          label: "负责人数",
          prop: "hosts_count",
          minWidth: 120,
          sortable: true,
        },
        {
          label: "活动数",
          prop: "activity_count",
          minWidth: 120,
          sortable: true,
        },
        {
          label: "文章数",
          prop: "articles_count",
          minWidth: 120,
          sortable: true,
        },
        {
          label: "收藏数",
          prop: "collect_count",
          minWidth: 120,
          sortable: true,
        },
        {
          label: "创建时间",
          prop: "create_time",
          minWidth: 200,
          sortable: true,
        },
      ]
    }
  },
  methods: {
    onEdit(row) {

    },
    getList(q) {
      return getGovMultiLevels(q)
    },
    // 筛选回调
    ok(e) {
      const pageData = { ...this.filterForm, ...e, page: 1 };
      this.$refs.govMultiLevel.getList(pageData)
    },
  },
}
</script>

<style scoped>

</style>
